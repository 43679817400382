
/* import manrope from assets/font */
@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');

body {
  background-color: #fff !important;
  color: #fff !important;
  margin: 0;
  padding: 0;
  height: 100vh;
}

h1, h2, h3, h4, h5, h6, span, p, label, input {
  font-family: 'Manrope', sans-serif;
  color: #1a202c !important;
}

.chakra-heading {
  font-family: 'Manrope', sans-serif;
}

.footer {
  font-family: 'Manrope', sans-serif;
}